<template>
  <div
    id="authenticationLayout"
    class="px-6 sm:px-10 md:px-20"
    v-lazy-container="{selector: 'img'}"
  >
    <SimpleTopBar />

    <div class="absolute w-2/5">
      <slot name="background-image" />
    </div>

    <div class="grid lg:grid-cols-2 justify-items-center md:justify-items-start sm:grid-cols-1 md:space-x-24 md:mt-16 2xl:mt-28 ">
      <div>
        <div class="relative w-full flex flex-col pt-14 items-end space-y-7">
          <BackToSpaceButton classes="pb-12" />
          <div>
            <slot name="text-page" />
          </div>
          <Socials :show-text="true"/>
        </div>
      </div>

      <div class="z-10 w-full md:w-4/5 2xl:w-3/6 md:-mt-6 pb-6 md:pb-0">
        <slot name="form" />
      </div>
    </div>
  </div>
</template>
<script>
import SimpleTopBar from "../other-layout/SimpleTopBar";
import BackToSpaceButton from "@/components/buttons/BackToSpaceButton.vue";
import Socials from "@/components/Socials";
export default {
  components: {Socials, BackToSpaceButton, SimpleTopBar}
};
</script>
<style scoped>
a {
    text-decoration: none;
}
</style>
